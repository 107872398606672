import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from '@models/menu-item.model';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public menuItems: MenuItem[];
  public logo: string;
  public showResponsiveMenu = false;
  public isMobile = false;
  constructor(private router: Router, public bpo: BreakpointObserver) {}

  ngOnInit() {
    this.bpo
      .observe([Breakpoints.XLarge, Breakpoints.Large, Breakpoints.Medium])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.logo = 'assets/img/ep-logo.svg';
          this.isMobile = false;
        }
      });

    this.bpo.observe([Breakpoints.Small, Breakpoints.XSmall]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.logo = 'assets/img/ep-logo-wide.svg';
        this.isMobile = true;
      }
    });
    this.menuItems = this.router.config.reduce((routes, route) => {
      if (route.data && route.data.nav) {
        routes.push({
          title: route.data.title,
          route: route.path
        });
      }
      return routes;
    }, []);
  }

  toggleMenu() {
    this.showResponsiveMenu = !this.showResponsiveMenu;
  }

  scrollUp() {
    const pageContent = document.getElementById('page-content');
    if (pageContent) {
      pageContent.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
}
