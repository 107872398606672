import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomePageComponent } from "@pages/home-page/home-page.component";
import { ContactPageComponent } from "@pages/contact-page/contact-page.component";
import { MeetUsPageComponent } from "@pages/meet-us-page/meet-us-page.component";
import { ResidencesPageComponent } from "@pages/residences-page/residences-page.component";
import { LifestylePageComponent } from "@pages/lifestyle-page/lifestyle-page.component";
import { PrivacyPolicyPageComponent } from "@pages/privacy-policy-page/privacy-policy-page.component";
import { CareersPageComponent } from "@pages/careers-page/careers-page.component";
import { DiningOptionsPageComponent } from "@pages/dining-options-page/dining-options-page.component";
import { ActivitiesPageComponent } from "@pages/activities-page/activities-page.component";
import { FaqPageComponent } from "@pages/faq-page/faq-page.component";

export const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    component: HomePageComponent,
    data: { nav: true, title: "Home" }
  },
  {
    path: "residences",
    pathMatch: "full",
    component: ResidencesPageComponent,
    data: { nav: true, title: "Residences" }
  },
  {
    path: "lifestyle",
    pathMatch: "full",
    component: LifestylePageComponent,
    data: { nav: true, title: "Lifestyle" }
  },
  {
    path: "activities",
    pathMatch: "full",
    component: ActivitiesPageComponent,
    data: { nav: true, title: "Activities" }
  },
  {
    path: "dining-options",
    pathMatch: "full",
    component: DiningOptionsPageComponent,
    data: { nav: false, title: "Dining Options" }
  },
  {
    path: "meet-us",
    pathMatch: "full",
    component: MeetUsPageComponent,
    data: { nav: true, title: "Meet Us" }
  },
  {
    path: "faq",
    pathMatch: "full",
    component: FaqPageComponent,
    data: { nav: true, title: "FAQ" }
  },
  {
    path: "contact",
    pathMatch: "full",
    component: ContactPageComponent,
    data: { nav: true, title: "Contact Us" }
  },
  {
    path: "careers",
    pathMatch: "full",
    component: CareersPageComponent,
    data: { nav: false, title: "Careers" }
  },
  {
    path: "privacy-policy",
    pathMatch: "full",
    component: PrivacyPolicyPageComponent,
    data: { nav: false, title: "Privacy Policy" }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
