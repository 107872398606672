import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef
} from "@angular/core";
import { Credentials, S3 } from "aws-sdk";
import { ContentItem } from "@models/content-item.model";

@Component({
  selector: "app-activities-page",
  templateUrl: "./activities-page.component.html",
  styleUrls: ["./activities-page.component.scss"]
})
export class ActivitiesPageComponent implements OnInit, AfterViewInit {
  @ViewChild("content") content: ElementRef;
  public activitiesItems: ContentItem[] = [
    {
      title: "Activity Calendars",
      content: `
      <p>
        Spend more time with friends, play Bridge or Bingo, travel, go to the theater, take in a play, go on weekly 
        excursions, visit with neighbors over coffee, and much more. The sky's the limit!
      </p>

      <p>Click on our current Activity Calendar to see the wide variety of functions you
      can enjoy. You can even print it out for quick reference. Participate in as many as you like, the choice is
      always yours.</p>

     `,
      image: "assets/img/calendar.jpg"
    },
    {
      title: "Weekly Excursions",
      content: `
          <p>
          We actively ask Emerald Pointe’s residents to tell us what they want to do, where they want to go, 
          and what they’d like to see.  We would love to hear your suggestions! Here are some examples of the
          types of excurions that our residents have helped us plan.
      </p>
      <div class="row">
        <div class="col-md-6">
          <ul>
            <li>Scenic Drives</li>
            <li>Beach</li>
            <li>Spirit Mountain Casino</li>
            <li>Timberline Lodge</li>
            <li>Movies</li>
            <li>Concerts and Plays</li>
          </ul>
        </div>

        <div class="col-md-6">
          <ul>
            <li>OMSI / Oregon Zoo</li>
            <li>Covered Bridges</li>
            <li>Woodburn Tulip Farms</li>
            <li>Schreiner Iris Gardens</li>
            <li>Evergreen Aviation Museam</li>
            <li>Columbia Gorge Waterfalls</li>
          </ul>
        </div>
      </div>
      `,
      image: "assets/img/collage-activities-all.jpg"
    },
    {
      title: "Art Galleries, Carousels, and Outdoors",
      content: `
      <p>
      Explore works of art, soak up the fresh air, and let your inner child play. We love to have fun all year-round. 
      We all love the outdoors and we also entertain ourselves inside at places such as art galleries and merry-go-rounds!
      </p>`,
      image: "assets/img/collage-activities-arts.jpg"
    },
    {
      title: "The Coast, Woods, and Gardens",
      content: `
      <p>
      Enjoy gentle hikes through the forest, see the sights of the Pacific Northwest, and stroll through beautiful 
      gardens that are right in our back yard.
      </p>`,
      image: "assets/img/collage-activities-woods.jpg"
    },
    {
      title: "Bean Bag Baseball",
      content: `
      <p>
      Batter Up! Enjoy the friendly competition, the thrill of victory, and the camaraderie that comes with playing 
      this version of our national pasttime. 
      </p>`,
      image: "assets/img/collage-activities-baseball.jpg"
    },
    {
      title: "Dancing, Big Bands, and Magic",
      content: `
      <p>
      Let the rhythm get you moving and get on the dance floor to your favorite tunes. We are fortunate to have so 
      many different musical acts and entertainers come to perform for us at Emerald Pointe. So much fun!
      </p>`,
      image: "assets/img/collage-activities-dance.jpg"
    },
    {
      title: "Honoring Our Veterans, Museums, and The Spruce Goose",
      content: `
      <p>
      As you can see, many veterans call Emerald Pointe home.  We have so may to thank for their service and sacrifice 
      in helping defend our freedom.
      </p>`,
      image: "assets/img/collage-activities-vets.jpg"
    },
    {
      title: "Mt. Angel, Oktoberfest, Pittock Mansion, and more",
      content: `
      <p>
      So many historic sights, wonderful small towns, and iconic landmarks to experience right here in the Willamette 
      Valley. From Portland's historic Pittock Mansion and it's amazing views, to the truly unique village atmosphere 
      of Mt. Angel and its Abbey, we strive to make each weekly excursion a fun and interesting experience.
      </p>`,
      image: "assets/img/collage-activities-groups.jpg"
    },
    {
      title: "Horseshoes, Lawns, and Car Shows",
      content: `
      <p>
      Great outdoor fun cannot get closer or more convenient than our own beautiful grounds. Enjoy friendly lawn games 
      in our backyard, or walks through beautifully landscaped nearby gardens, or see classic cars and hot rods in our 
      parking lot at Emerald Pointe's own car shows.
      </p>`,
      image: "assets/img/college-outdoor-fun-1.jpg"
    },
    {
      title: "Farms, Mills, and Road Trips",
      content: `
      <p>
      Take a trip through time, see how farms and mills helped shape our area, and discover interesting facts about 
      our rich agricultural history here in the mid-Willamette Valley.
      </p>`,
      image: "assets/img/collage-activities-mill.jpg"
    },
    {
      title: "Parties, Talent Shows, and Bazaars",
      content: `
      <p>
      There is never a shortage of indoor parties, events, and performances here at Emerald Pointe, where you can be 
      a star! Emerald Pointe's Got Talent! Whether you sing, act, dance, play an instrument, bake, sew, or craft, we 
      love to see you express your creative side.
      </p>`,
      image: "assets/img/collage-activities-talent.jpg"
    },
    {
      title: "Yummy Treats and Baking Competitions",
      content: `
      <p>
      Indulge in the delicious desserts, pastries, cookies, and goodies that are crafted by our professional staff or 
      even created by our talented residents! Sweet!
      </p>`,
      image: "assets/img/collage-activities-treats.jpg"
    }
  ];
  public calendars = [];

  constructor() {}

  ngOnInit() {
    const s3 = new S3({
      region: "us-west-2",
      apiVersion: "2006-03-01",
      params: { Bucket: "calendars.emeraldpointekeizer.com" },
      credentials: new Credentials({
        accessKeyId: "AKIAZHEBI5MGSINDOJN7",
        secretAccessKey: "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"
      })
    });

    // call to calendar bucket to list all calendars
    s3.listObjects((err, data) => {
      if (err) {
        console.log(err);
      } else {
        const dateRegex = /[0-9]+-[0-9]+/;

        // build up array of calendar dates, urls, and displayNames
        data.Contents.map(item => {
          const filename = item.Key;
          const rawDate = dateRegex.exec(filename);

          // ignore ill-formed calendar names or other files which may be in the bucket
          if (rawDate) {
            const date = new Date(rawDate[0]);
            const month = date.toLocaleDateString("en-US", {
              timeZone: "UTC",
              month: "long"
            });
            const year = date.getUTCFullYear();
            const calendarDisplayName = month + " " + year;

            this.calendars.push({
              date,
              title: calendarDisplayName,
              url: "http://calendars.emeraldpointekeizer.com/" + filename
            });
          }
        });

        // sort by date and slice out last three calendars
        this.calendars = this.calendars
          .sort((a, b) => {
            return a.date - b.date;
          })
          .slice(-3);

        // append to activities calendar item content
        this.activitiesItems[0].content = this.activitiesItems[0].content
          .concat(`
        <style>
          .calendars {
            margin-top: 20px;
            padding-left: 0;
            text-align: center;
          }
          .calendar-button {
            width: 145px;
            color: white;
            background-color: #316955;
            font-size: 16px;
            display: inline-block;
            margin-top: 5px;
            list-style-type: none;
            border: 2px #316955 solid;
            border-radius: 3px;
            transition: all 0.6s ease-out;
          }
          .calendar-button > a {
            color: white;
          }
          .calendar-button:hover {
            background-color: #214539;
            border: 2px #214539 solid;
            transition: all 0.3s ease-out;
          }
          .calendar-link {
            text-decoration: none;
            padding: 5px 10px;
            display: block;
            width: 100%;
            height: 100%;
          }
        </style>
        <ul class="calendars">
          <li class="calendar-button"><a class="calendar-link" href="${this.calendars[0].url}" target="_blank">${this.calendars[0].title}</a></li>
          <li class="calendar-button"><a class="calendar-link" href="${this.calendars[1].url}" target="_blank">${this.calendars[1].title}</a></li>
          <li class="calendar-button"><a class="calendar-link" href="${this.calendars[2].url}" target="_blank">${this.calendars[2].title}</a></li>
        </ul>
        `);
      }
    });
  }

  ngAfterViewInit() {
    this.content.nativeElement.focus();
  }
}
