import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MeetUsPageComponent } from './meet-us-page/meet-us-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { ServicesPageComponent } from './services-page/services-page.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { ResidencesPageComponent } from './residences-page/residences-page.component';
import { LifestylePageComponent } from './lifestyle-page/lifestyle-page.component';
import { SharedModule } from '@shared/shared.module';
import { PrivacyPolicyPageComponent } from './privacy-policy-page/privacy-policy-page.component';
import { CareersPageComponent } from './careers-page/careers-page.component';
import { DiningOptionsPageComponent } from './dining-options-page/dining-options-page.component';
import { ActivitiesPageComponent } from './activities-page/activities-page.component';
import { FaqPageComponent } from './faq-page/faq-page.component';

@NgModule({
  declarations: [
    HomePageComponent,
    ServicesPageComponent,
    ContactPageComponent,
    MeetUsPageComponent,
    ResidencesPageComponent,
    LifestylePageComponent,
    PrivacyPolicyPageComponent,
    CareersPageComponent,
    DiningOptionsPageComponent,
    ActivitiesPageComponent,
    FaqPageComponent
  ],
  imports: [CommonModule, NgbModule, SharedModule, FormsModule, ReactiveFormsModule]
})
export class PagesModule {}
