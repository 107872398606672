import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FeatureTableComponent } from './feature-table/feature-table.component';
import { NavbarComponent } from '@shared/navbar/navbar.component';
import { FooterComponent } from '@shared/footer/footer.component';
import { AltImagePanelComponent } from '@shared/alt-image-panel/alt-image-panel.component';
import { SafeHtmlPipe } from '@pipes/safe-html.pipe';
import { SafeStylePipe } from '@pipes/safe-style.pipe';

@NgModule({
  declarations: [
    FeatureTableComponent,
    NavbarComponent,
    FooterComponent,
    AltImagePanelComponent,
    SafeHtmlPipe,
    SafeStylePipe
  ],
  imports: [CommonModule, RouterModule],
  exports: [
    FooterComponent,
    NavbarComponent,
    FeatureTableComponent,
    AltImagePanelComponent,
    SafeHtmlPipe,
    SafeStylePipe,
    RouterModule
  ]
})
export class SharedModule {}
