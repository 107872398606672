import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ICareersContactInfo } from '@models/careers-contact-info';
import { CareersContactService } from 'src/app/services/careers-contact.service';

@Component({
  selector: 'app-careers-page',
  templateUrl: './careers-page.component.html',
  styleUrls: ['./careers-page.component.scss']
})
export class CareersPageComponent implements OnInit, AfterViewInit {
  public successMessage: string;
  public errorMessage: string;
  public validationMessage: string;
  public contactForm: FormGroup;

  constructor(private careerContactService: CareersContactService, private fb: FormBuilder) {
    this.successMessage = '';
    this.errorMessage = '';
    this.validationMessage = '* Please fill in all required fields';
  }
  @ViewChild('content') content: ElementRef;

  ngOnInit() {
    // form init
    this.contactForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required]],
      contactMethod: ['Email', Validators.required],
      position: ['', Validators.required],
      comments: ['']
    });
  }

  ngAfterViewInit() {
    this.content.nativeElement.focus();
  }

  submitContact(form: FormGroup): void {
    const contact: ICareersContactInfo = {
      firstName: form.value.firstName,
      lastName: form.value.lastName,
      phone: form.value.phone,
      email: form.value.email,
      contactMethod: form.value.contactMethod,
      position: form.value.position,
      comments: form.value.comments
    };

    console.log(contact);

    this.careerContactService.submitContactRequest(contact).subscribe(() => this.showSuccess(), () => this.showError());
  }

  showSuccess(): void {
    // reset with one space to not show validation required message after submit
    this.contactForm.reset({
      ...this.contactForm.value,
      firstName: ' ',
      lastName: ' ',
      phone: ' ',
      email: ' ',
      position: ' ',
      comments: ' '
    });
    this.successMessage = 'Your message has been sent!';
  }

  showError(): void {
    this.errorMessage =
      'We are experiencing difficulties processing your request.\n\
Please contact us at (503) 390-7700, and we would happy to speak with you in person.';
  }
}
