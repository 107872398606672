import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {} from "googlemaps";
import { IContactInfo } from "@models/contact-info";
import { ContactService } from "src/app/services/contact.service";

@Component({
  selector: "app-contact-page",
  templateUrl: "./contact-page.component.html",
  styleUrls: ["./contact-page.component.scss"],
})
export class ContactPageComponent implements OnInit, AfterViewInit {
  @ViewChild("content") content: ElementRef;

  public successMessage: string;
  public errorMessage: string;
  public validationMessage: string;
  public contactForm: FormGroup;

  @ViewChild("map") mapElement: any;
  public infoWindow: google.maps.InfoWindow;
  public map: google.maps.Map;
  public marker: google.maps.Marker;
  public placesService: google.maps.places.PlacesService;
  public placesRequest: google.maps.places.PlaceDetailsRequest;
  public submitting = false;

  constructor(private contactService: ContactService, private fb: FormBuilder) {
    this.successMessage = "";
    this.errorMessage = "";
    this.validationMessage = "* Please fill in all required fields";
  }

  ngOnInit(): void {
    // google map init
    const mapProperties = {
      center: new google.maps.LatLng(45.019402, -123.01729),
      zoom: 12,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };
    this.map = new google.maps.Map(
      this.mapElement.nativeElement,
      mapProperties
    );
    this.marker = new google.maps.Marker({
      position: { lat: 45.019402, lng: -123.01729 },
      map: this.map,
      title: "Emerald Pointe Senior Living Community",
    });

    // form init
    this.contactForm = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      phone: ["", [Validators.required]],
      email: ["", [Validators.required]],
      contactMethod: ["Email", Validators.required],
      lookingFor: ["Self", Validators.required],
      comments: [""],
    });
  }

  ngAfterViewInit() {
    this.content.nativeElement.focus();
  }

  submitContact(form: FormGroup): void {
    const contact: IContactInfo = {
      firstName: form.value.firstName,
      lastName: form.value.lastName,
      phone: form.value.phone,
      email: form.value.email,
      contactMethod: form.value.contactMethod,
      lookingFor: form.value.lookingFor,
      comments: form.value.comments,
    };
    this.submitting = true;
    this.contactService.submitContactRequest(contact).subscribe(
      () => this.showSuccess(),
      () => this.showError()
    );
  }

  showSuccess(): void {
    // reset with one space to not show validation required message after submit
    this.contactForm.reset({
      ...this.contactForm.value,
      firstName: " ",
      lastName: " ",
      phone: " ",
      email: " ",
      comments: " ",
    });
    this.submitting = false;
    this.successMessage =
      "Your message has been sent! One of our representatives will be in touch shortly.";
  }

  showError(): void {
    this.errorMessage =
      "We are experiencing difficulties processing your request.\n\
Please contact us at (503) 390-7700, and we would happy to speak with you in person.";
  }
}
