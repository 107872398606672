import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-privacy-policy-page',
  templateUrl: './privacy-policy-page.component.html',
  styleUrls: ['./privacy-policy-page.component.scss']
})
export class PrivacyPolicyPageComponent implements OnInit, AfterViewInit {
  @ViewChild('content') content: ElementRef;

  constructor() {}

  ngOnInit() {}
  ngAfterViewInit() {
    this.content.nativeElement.focus();
  }
}
