import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ContentItem } from '@models/content-item.model';

@Component({
  selector: 'app-dining-options-page',
  templateUrl: './dining-options-page.component.html',
  styleUrls: ['./dining-options-page.component.scss']
})
export class DiningOptionsPageComponent implements OnInit, AfterViewInit {
  @ViewChild('content') content: ElementRef;

  public diningItems: ContentItem[] = [
    {
      title: 'Great Food that You Will Love',

      content: `
      <p>
      Enjoy delicious home-style meals prepared by our professional chef and her exceptional staff, or you can enjoy cooking
      in the fully-equipped kitchen of your residence. You have many yummy options.
      </p>
     `,
      image: 'assets/img/chef-collage.jpg',

      cta: 'View and Print Our Sample Menus',
      hrefLink: '/assets/sample-menus/epksamplemenu.pdf'
    },
    {
      title: 'Restaurant-Style Dining',
      content: `
      <p>
      Emerald Pointe offers a beautiful dining room and meals served at your table. It's like dining in a nice
      restaurant or homey dining room with friends - not an institutional cafeteria.
      </p>`,
      image: 'assets/img/restaurant2crop.jpg'
    },
    {
      title: 'Optional Meals',
      content: `
          <p>
          Other adult communities make you pay for three meals a day, whether you eat them or not. At Emerald Pointe, we
          believe you should have choices and not be forced to eat meals you may not like, nor pay for meals you may not
          eat.
      </p>`,
      image: 'assets/img/foodservice1.jpg'
    },
    {
      title: 'Flexible Meal Plans with Real Choices',
      content: `
      <p>
      Our basic meal plan includes one meal per day. Each day you choose which meal you would like to include:
      breakfast, lunch, or dinner. You can purchase additional meals one at a time or choose one of our alternate meal
      plans so that you can have two, or even three meals prepared for you per day.
      </p>`,
      image: 'assets/img/diningroomcasual.jpg'
    },
    {
      title: 'Friends and Family Welcome',
      content: `
      <p>
      Your friends and family are welcome to join you for meals any time. You'll find our guest meal prices
      surprisingly inexpensive for such a high-quality dining experience. All we ask is that you tell us how many
      guests you're expecting so our staff can be prepared to provide you with the premium service you deserve
    </p>
      `,
      image: 'assets/img/friendsfamilydining.jpg'
    },
    {
      title: 'Special Diets',
      content: `
      <p>
      Our chef can prepare meals you love that fit perfectly with your special medical and dietary needs, all for no
      extra charge.
          </p>
      `,
      image: 'assets/img/chef3.jpg'
    },
    {
      title: 'On The Lighter Side',
      content: `
      <p>
      Enjoy a light snack in our Coffee Lounge where you can relax, have an ice cream cone, or just sip a cup of
      coffee with your friends in a relaxed, less formal setting.
      </p>
      `,
      image: 'assets/img/foodsnacks3people.jpg'
    }
  ];
  constructor() {}

  ngOnInit() {}
  ngAfterViewInit() {
    this.content.nativeElement.focus();
  }
}
