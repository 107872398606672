import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit
} from "@angular/core";
import { Floorplan } from "@models/floorplan.model";
import stickybits from "stickybits";
@Component({
  selector: "app-feature-table",
  templateUrl: "./feature-table.component.html",
  styleUrls: ["./feature-table.component.scss"]
})
export class FeatureTableComponent implements OnInit, AfterViewInit {
  @Input() floorplans: Floorplan[];
  @ViewChild("featureTableHeader") featureTableHeader: HTMLElement;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    stickybits((this.featureTableHeader as any).nativeElement);
  }
}
