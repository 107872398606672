import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ICareersContactInfo } from '@models/careers-contact-info';

@Injectable({
  providedIn: 'root'
})
export class CareersContactService {
  constructor(private _http: HttpClient) {}

  public submitContactRequest(contact: ICareersContactInfo) {
    return this._http.post(
      'https://7si16x0j9e.execute-api.us-west-2.amazonaws.com/default/ep-careers-contact',
      contact
    );
  }
}
