import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Credentials, S3 } from 'aws-sdk';
import { ContentItem } from '@models/content-item.model';

@Component({
  selector: 'app-lifestyle-page',
  templateUrl: './lifestyle-page.component.html',
  styleUrls: ['./lifestyle-page.component.scss']
})
export class LifestylePageComponent implements OnInit, AfterViewInit {
  @ViewChild('content') content: ElementRef;
  public lifestyleItems: ContentItem[] = [
    {

      title: 'Activities',

      content: `
      <p>
        Learn all about the daily activities planned on our Activity Calendars and enjoy snapshots of just a few of the events, games, contests, and weekly excursions that our residents help us plan.
      </p>
     `,
      image: 'assets/img/collage-activities-all.jpg',

      cta: 'View our activities',
      ctaLink: '/activities'
    },
    {
      title: 'Dining Options',
      content: `
      <p>
      Enjoy delicious homestyle meals prepared by our professional chef and her exceptional staff or you can
      enjoy cooking in the fully equipped kitchen of your apartment home. You may even choose to dine at one of many excellent nearby restaurants. You'll have many yummy options at Emerald Pointe. 
      </p>`,
      image: 'assets/img/dining-options.jpg',
      cta: 'Learn More about our Dining Options',
      ctaLink: '/dining-options'
    },
    {
      title: 'Transportation',
      content: `
          <p>
        We provide scheduled transportation to banks, doctors' appointments, shopping, weekly excursions, and more. There's
        also a convenient public bus stop right near our main entrance.
      </p>`,
      image: 'assets/img/busoutside.jpg'
    },
    {
      title: 'Private Fireside Dining Room and Coffee Lounge',
      content: `
      <p>
        Host dinners, birthdays, and holiday celebrations for your family and friends. Let our professional chef
        and staff do all the cooking and cleaning! Additionally, our Coffee Lounge offers a relaxed
        atmosphere where you can enjoy coffee, tea, hot chocolate, or even an ice cream cone with friends and
        family, all at no charge.
      </p>`,
      image: 'assets/img/friendsfamilydining2.jpg'
    },
    {
      title: 'Beauty Salon',
      content: `
      <p>
      At our full service beauty salon, ladies and gentlemen alike can get their hair done, have a manicure,
      or even a pedicure, all without leaving the comfort of Emerald Pointe.
    </p>
      `,
      image: 'assets/img/beauty-salon.jpg'
    },
    {
      title: 'Community Room',
      content: `
      <p>
            In our Community Room you'll enjoy a host of activities, from Wii Bowling, to Bean Bag Baseball, Bingo,
            and movie night. Join the line dancing team, or enjoy a puzzle together with your new neighbors and
            friends. For those that don't have their own computer, there's even a free computer to access the
            Internet, all at no charge.
          </p>
      `,
      image: 'assets/img/community-room.jpg'
    },
    {
      title: 'Activity Rooms',
      content: `
      <p>
        For smaller groups that want to get together, our second floor activity rooms can accommodate up to a dozen people comfortably. Cards, Bunko, Bible Study, and a host of other
        groups are available for your participation and enjoyment. There's no reason to sit around in your room, when you can be out having fun!
      </p>
      `,
      image: 'assets/img/activity-rooms.jpg'
    },
    {
      title: 'Resident Lounge',
      content: `
      <p>
        For those quiet times when you just want to be out of your home and enjoy a comfy, quiet corner to sit
        by yourself and read, or just talk with your friends, our Resident Lounge with it's inviting overstuffed
        chair is perfect.
      </p>
      `,
      image: 'assets/img/fireplace1.jpg'
    }
  ];

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.content.nativeElement.focus();
  }
}
