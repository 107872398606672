import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ContentItem } from '@models/content-item.model';

@Component({
  selector: 'app-alt-image-panel',
  templateUrl: './alt-image-panel.component.html',
  styleUrls: ['./alt-image-panel.component.scss']
})
export class AltImagePanelComponent implements OnInit, AfterViewInit {
  constructor() {}
  @Input() imageCols = 6;
  @Input() items: ContentItem[];
  @Input() initialImageLeft: boolean;
  public evenImageOrder: number;
  public oddImageOrder: number;

  ngOnInit() {
    this.evenImageOrder = this.initialImageLeft ? 0 : 1;
    this.oddImageOrder = this.initialImageLeft ? 1 : 0;
  }
  ngAfterViewInit() {}
}
