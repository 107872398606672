import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ContentItem } from '@models/content-item.model';
import { Floorplan } from '@models/floorplan.model';

@Component({
  selector: 'app-residences-page',
  templateUrl: './residences-page.component.html',
  styleUrls: ['./residences-page.component.scss']
})
export class ResidencesPageComponent implements OnInit, AfterViewInit {
  @ViewChild('content') content: ElementRef;

  public residenceItems: ContentItem[] = [
    {
      title: 'Take Pride in Living at Emerald Pointe',
      content: `<p>
      Enjoy the curb appeal of our handsome retirement community with professionally manicured grounds that you’ll be 
      proud to call “home”.  Our covered front entrance protects you from the rain, as well as the hot summer sun.  
      Our lush green landscape and walking paths are perfect for daily strolls, taking in fresh air, as well as lawn games.
                </p>`,
      image: 'assets/img/collage-exterior.jpg'
    },
    {
      title: 'Modern Features',
      content: `<p>
      All of Emerald Pointe's apartment homes feature central forced air heating and air conditioning, just like your 
      home now, with easy-open windows, state-of-the-art safety systems, an emergency call system, as well as smoke alarm and fire 
      sprinkler systems.  Our entire building is protected with a door access control system and a closed-circuit video 
      surveillance system.
                </p>`,
      image: 'assets/img/residences-2.jpg'
    },
    {
      title: 'Your Independence',
      content: `<p>
      Enhancing your independence and freedom is our mission. Each of Emerald Pointe's apartment homes feature a 
      complete kitchen including a full-size range with oven, refrigerator, microwave, dishwasher 
      (except in studio residences), and a host of other features to support your independence.
                </p>`,
      image: 'assets/img/residences-3.jpg'
    },
    {
      title: 'Elegance and Comfort',
      content: `<p>
                  All of the residences at Emerald Pointe are designed with you in mind.
                </p>
                <p>
                All apartment homes at Emerald Pointe are designed with you in mind. With nine floor plans to 
                choose from, Emerald Pointe's apartment homes offer a variety of sizes, configurations, and views, as well 
                as decks and walk-in closets.
                </p>`,
      image: 'assets/img/residences-1.jpg'
    }
  ];

  public floorplans: Floorplan[] = [
    {
      name: 'Studio A',
      pdfurl: 'assets/floorplans/studio1.pdf',
      sqft: 409,
      baths: 1,
      hasDeck: false,
      hasWalkinCloset: false
    },
    {
      name: 'Studio B',
      pdfurl: 'assets/floorplans/studio2.pdf',
      sqft: 479,
      baths: 1,
      hasDeck: false,
      hasWalkinCloset: true
    },
    {
      name: 'Studio C',
      pdfurl: 'assets/floorplans/studio3.pdf',
      sqft: 507,
      baths: 1,
      hasDeck: false,
      hasWalkinCloset: true
    },
    {
      name: 'One Bedroom A',
      pdfurl: 'assets/floorplans/1bed1.pdf',
      sqft: 663,
      baths: 1,
      hasDeck: true,
      hasWalkinCloset: false
    },
    {
      name: 'One Bedroom B',
      pdfurl: 'assets/floorplans/1bed2.pdf',
      sqft: 745,
      baths: 1,
      hasDeck: true,
      hasWalkinCloset: false
    },
    {
      name: 'One Bedroom C',
      pdfurl: 'assets/floorplans/1bed3.pdf',
      sqft: 723,
      baths: 1,
      hasDeck: false,
      hasWalkinCloset: true
    },
    {
      name: 'Two Bedroom A',
      pdfurl: 'assets/floorplans/2bed1bath.pdf',
      sqft: 928,
      baths: 1,
      hasDeck: true,
      hasWalkinCloset: false
    },
    {
      name: 'Two Bedroom B',
      pdfurl: 'assets/floorplans/2bed2bath1.pdf',
      sqft: 930,
      baths: 2,
      hasDeck: true,
      hasWalkinCloset: false
    },
    {
      name: 'Two Bedroom C',
      pdfurl: 'assets/floorplans/2bed2bath2.pdf',
      sqft: 988,
      baths: 2,
      hasDeck: false,
      hasWalkinCloset: true
    }
  ];
  // public floorplans: [] = [
  //   {
  //     title: 'Studio A',
  //     attributes: [
  //       {
  //         name: 'Deck',
  //         value: false
  //       }
  //     ]
  //   }
  // ];
  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.content.nativeElement.focus();
  }
}
