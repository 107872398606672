import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IContactInfo } from '@models/contact-info';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  constructor(private _http: HttpClient) {}

  public submitContactRequest(contact: IContactInfo) {
    const timestamp = new Date()
      .toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour12: true,
        hour: '2-digit',
        minute: '2-digit'
      })
      .toLowerCase()
      .replace(',', '');

    const endpoint = `
https://abbi.advantageanywhere.com/srp_support/landingpage.aspx\
?CID=A90D1D44-54E1-4AE7-A5D6-1DF33264E22A&SID=70\
&COMMAND_KEY=SAVE_SRP_DATA\
&wmid=0&hRefData=\
&idkey=FromURL\
&hClientDateTime=${timestamp}\
&sd=\
~FirstName=${contact.firstName}\
~LastName=${contact.lastName}\
~EMail=${contact.email}\
~Phone1=${contact.phone}\
~Fitness=${contact.contactMethod}\
~CUSTOM_FORM_LIST_64595=${contact.lookingFor}\
~CUSTOM_FORM_NOTE_25577=${contact.comments}~
`;

    return this._http.get(endpoint, { responseType: 'text' });
  }
}
