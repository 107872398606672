import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-meet-us-page',
  templateUrl: './meet-us-page.component.html',
  styleUrls: ['./meet-us-page.component.scss']
})
export class MeetUsPageComponent implements OnInit, AfterViewInit {
  @ViewChild('content') content: ElementRef;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.content.nativeElement.focus();
  }
}
