import { Component, ElementRef, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ContentItem } from '@models/content-item.model';
import { BaseAnimationRenderer } from '@angular/platform-browser/animations/src/animation_renderer';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit, AfterViewInit {
  @ViewChild('content') content: ElementRef;

  public homeItems: ContentItem[] = [
    {
      title: 'Welcome Home',
      image: 'assets/img/ep-front-crop.jpg',
      content: `<p>
                  We designed the Emerald Pointe experience to provide you with the perfect blend of independence, comfort, 
                  activity, and sense of community. Our facility was specifically designed with your needs and enjoyment in mind, 
                  and we welcome you to come and visit so you can see just how special it is.</p>`,
                  cta: 'Contact Us',
                  ctaLink: '/contact',            
    },
    {
      title: 'The Lifestyle You Deserve',
      image: 'assets/img/winehappyoutdoors.jpg',
      content: `<p>
                  At Emerald Pointe, the phrase "The Lifestyle You Deserve" is not a cliche. It defines our commitment to
                  provide the high-quality lifestyle that you deserve after working hard all those years to make a home
                  and raise your family. Our owner and his family have been in the service industry since 1981, so taking great 
                  care of Willamette Valley residents is in our blood.</p>`,                  
                  cta: 'Emerald Pointe Lifestyle',
                  ctaLink: '/lifestyle', 
    },
    {
      title: 'You Have a Say',
      content: `
      <p>
        At Emerald Pointe, we strive to enhance your
        independence and lifestyle even more, so we rely on input from our residents. We meet regularly with resident committees 
        and ask about the types of activities they want to enjoy. As a resident, you'll have a big part in choosing the 
        foods we serve, the excursions we take, the parties we throw, as well as shaping the
        full schedule of daily activities.
      </p>`,
      image: 'assets/img/evelyn-portrait-2.jpg'
    },
    {
      title: 'Always Something Fun Going On!',
      content: `
      <p>A comprehensive activities program is central to enjoying retirement
      living at Emerald Pointe.</p>

      <p>Our activities director works with our residents to arrange a whole host of
      activities such as Wii bowling, Bean Bag Baseball, line dancing, bible study,
      bingo, wine club, exercise classes, bridge, bunko, pinochle, tile rummy, and
      many other functions, as well as entertainment and weekly excursions.&nbsp; Our private ownership combined 
      with our vast local relationships gives us excellent flexibility when it comes to catering to resident's 
      ideas and suggestions.</p>`,
      cta: 'Learn About our Activities',
      ctaLink: '/activities', 
      
      image: 'assets/img/dancing.jpg'
    },
    {
      title: 'We\'ll Take Care of That for You!',
      image: 'assets/img/plumber-resident.jpg',
      content: `<p>
                  Are you tired of the labors of home ownership such as painting, plumbing, lawn care and roof leaks? 
                  What about vacuuming, cleaning toilets,
                  and mopping floors? Let Emerald Pointe take care of it for you. It's all included!
                </p>
                <p>
                  Ask how we can tailor a housekeeping plan that works for you. Do as much or as little as you like. 
                  Take delight in fresh bed sheets every week without lifting a finger. Our
                  weekly service does all the heavy lifting while giving you the freedom to keep the tasks you enjoy
                  doing yourself.
                </p>`
    },
    {
      title: 'Our Goal',
      content: `Emerald Pointe was created by our owner, Dr. Jeff Hawkins, to provide his parents,
      grandparents, and in-laws with "the lifestyle they deserve". Although his grandmother and father have passed,
      Dr. Hawkins continues to strive to provide his mother with the independence, dignity, freedom, and joy that she 
      deserves. And this committment to a quality lifestyle is extended to all
      honored parents and grandparents of Emerald Pointe.`,
      cta: 'Contact Us',
      ctaLink: '/contact', 
      image: 'assets/img/mom-and-dad.png'
    }
  ];

  public testimonials: ContentItem[] = [
    {
      content: `<p class="mb-0">
      We can still hardly believe our good fortune that when we went looking for a place for Dad three years ago,
      the perfect place was just two minutes for our own house. His corner apartment has been ideal for him and he's
      going to miss sitting on his patio in the sun, chatting with passersby. The entire staff has treated him like
      family. We can't say enough good things about the way everyone there has cared for him and put up with the
      demands of our whole family as we've advocated for him. He has a special place in his heart for Cari, Pat,
      Leah and Butch. Syttende Mai will never be the same without Leah spoiling him with Norwegian decorations on
      his door... Thank you so much for being the safe and friendly home we were hoping for three years ago when we
      uprooted him from his life in North Bend.
    </p>
    <footer class="blockquote-footer">Chet and Chris P.</footer>`
    },
    {
      content: `<p class="mb-0">
    Thank you for your generous hospitality in letting us use the private dining room for Mary S*****'s birthday
    lunch. We received such willing help from you - from the front desk to the servers and cook staff. The lunches
    were all tasty and presented attractively. Your servers and cook staff joined us, at their request, to sing
    Happy Birthday to Mary. That was an unexpected treat & honor. Thank you for the kind and caring ways you treat
    your residents & their families.
  </p>
  <footer class="blockquote-footer">Mary S*****'s Family</footer>`
    },
    {
      content: `<p class="mb-0">
    A year ago on this date we moved into Emerald Pointe. We celebrate today our first anniversary here. What a
    wise and positive choice to move here has proven to be. We take this opportunity to thank you, Cari, for the
    special ways in which you have blessed and enriched our lives by your services, but more especially by who you
    are. This note would include all, who in their individual services, have contributed to our well being. Our
    profound thanks.
  </p>
  <footer class="blockquote-footer">Bob and Mary</footer>`
    },
    {
      content: `<p class="mb-0">
    Today is my first anniversary for moving into Emerald Pointe and it only confirms what I thought a year ago ~
    I made the right choice. This past year has just flown by, and never once have I regretted making the move. Of
    course, you, Cari and the rest of the staff here can take credit for that. I have watched with respect,
    admiration and appreciation at the great job you all do and give thanks every day.
  </p>
  <footer class="blockquote-footer">Laura</footer>`
    },
    {
      content: `<p class="mb-0">
    We want to tell you how nice the luncheon was yesterday that Karen and her servers, Frankie and Omari, served
    our travel club. Many commented on the great food and the beautiful settings. We feel fortunate to be able to
    entertain in such a special place.
  </p>
  <footer class="blockquote-footer">Mr. and Mrs. K</footer>`
    },
    {
      content: `<p class="mb-0">
    Lois and I have been residents at Emerald Pointe for over two years. One of our requirements was local
    ownership and management. Our request has been met, much to our satisfaction. Our apartment feels like home.
    Lois is somewhat of a homebody, and Bill is one who likes to be out and about and involved. Emerald Pointe
    allows us to be home together, but follow our own way.
  </p>
  <footer class="blockquote-footer">Mr. and Mrs. H</footer>`
    },
    {
      content: `<p class="mb-0">
    I love Emerald Pointe, to me it’s “home” and an extension of friends and family. I moved away for a couple
    months to a new larger and supposedly better place, but I could not wait to get back here. We care a lot about
    each other and we have been together over four years. I’d invite you to come and see for yourself. Enjoy some
    cookies and ice cream while you visit. At meetings, the chef listens to our suggestions. If I have a
    maintenance request, the maintenance staff get it done quickly.
  </p>
  <footer class="blockquote-footer">BT</footer>`
    },
    {
      content: `<p class="mb-0">
    Everyone is very nice, people are friendly. Services are great.
  </p>
  <footer class="blockquote-footer">ED</footer>`
    },
    {
      content: `<p class="mb-0">
    After the death of my husband, I felt the need for a change. I considered some other retirement communities,
    but when my daughter showed me Emerald Pointe, I was very impressed with the reception from the staff and
    residents. The atmosphere had the feeling of comfort and spaciousness. I loved the décor, the cleanliness, and
    the many, many nooks and crannies full of activities, such as the full library, exercise equipment, many
    activities, and of course the favorite spot, the ice cream machine, which is the spot to talk and get
    acquainted with the others residents. The calendar is full of activities to suit any and all who wish to
    participate, plus outside trips to see sights, and experience drives in the countryside. My personal favorites
    are bingo, Wii Bowling, beach ball, exercise, bean bag baseball, and outside trips.
  </p>
  <footer class="blockquote-footer">PC</footer>`
    },
    {
      content: `<p class="mb-0">
    When we put our house up for sale, I told a friend who lives in Keizer that we now need to look for a
    retirement home. She saw Emerald Pointe when it was new and having an “Open House. So we came to see it, and
    decided that as soon as we sell the house, we will move there. We like the prompt help from the staff, the bus
    driver Brian, maintenance, and the spacious living apartment.
  </p>
  <footer class="blockquote-footer">Mr. and Mrs. H</footer>`
    },
    {
      content: `<p class="mb-0">
    My husband and I moved here on November 20, 2012. We had sold our house and moved to a residential community
    in South Salem. We only stayed two months because there were many things we disliked. I can truly say that we
    love it here at Emerald Pointe. The people are wonderful, the staff is great, and the food is perfect. The
    facility is beautiful. Living here almost seems like living in our previous home. I can’t say enough good
    things about it. I hope to spend many happy years right here.
  </p>
  <footer class="blockquote-footer">IW</footer>`
    }
  ];

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.content.nativeElement.focus();
  }
}
